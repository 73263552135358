<template>
<v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>Dolly</v-card-title>
        <v-card-text>
            <v-row>
            <v-col cols="3">
                <v-text-field
                                v-model="dolly.no_economico"
                                label="No. Económico"
                                class="uppercase"
                                
                            /> 
            </v-col>
            <v-col cols="6">
               <v-textarea
                                label="Descripción"
                                class="uppercase"
                                v-model="dolly.descripcion"

                                auto-grow
                                outlined
                                rows="6"
                                row-height="25"
                                shaped
                            />
            </v-col>

            </v-row>
            <v-row>
                  <v-col cols="4">
                          <v-file-input
  truncate-length="15"
  label="Tarjeta de Circulación"
   @change="onFileTCChange"
></v-file-input>
                        </v-col>



        
                <v-col cols="4">
                          <v-file-input
  truncate-length="15"
  label="Póliza de Seguro"
   @change="onFilePolizaChange"
></v-file-input>
                        </v-col>  
                        <v-col cols="4">
                          <v-file-input
  truncate-length="15"
  label="Factura"
   @change="onFileFactChange"
></v-file-input>
                        </v-col>  
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-btn color="success" @click="savedolly">
                     <v-icon>mdi-content-save-outline</v-icon>
                  Guardar
                </v-btn>
              </v-col>
              </v-row>
        </v-card-text>
      </base-card>
    </v-col>

</v-row>
</template>
<script>
import { url } from "../../main.js";
import moment from "moment-timezone";

export default {

   metaInfo: {
      // title will be injected into parent titleTemplate
      title: "Dolly"
    },
    data () {
      return {
                     showScheduleForm: false,

          enviar: {},
        iddolly: this.$route.params.iddolly,
          edit:false,
              dolly: {
        iddolly: 0,

        no_economico: "",
        marca: "",
        modelo: "",
        anio: "",
        placas: "",
        idtipo: 0,
       
          tarjeta_circulacion: null,
          tarjeta_fm: null,
          tarjeta_emision: null,
          factura: null,
          seguro: null,
        
      }
            }
    },
          mounted(){
this.permiso = localStorage.getItem("permission");
    if(this.iddolly==0)
    {


    }else{
    this.editdolly();
   
    }
    },
    methods:{
        editdolly() {
      this.axios
        .get(url + "apiDollys.php/dolly/" + this.iddolly)
        .then((respuesta) => {
          console.log(respuesta);
          this.dolly = respuesta.data;
          this.edit = true;
        });
    }, 
  
        onFileFactChange(e) {
      this.dolly.factura = e.target.files[0];
      console.log(e.target.files);
    },
    onFileFMChange(e) {
      console.log(e);

      this.dolly.tarjeta_fm = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    },
    onFileTVChange(e) {
      console.log(e);

      this.dolly.tarjeta_emision = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    },
    onFilePolizaChange(e) {
      console.log(e);

      this.dolly.seguro = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    },
    onFileTCChange(e) {
      console.log(e);

      this.dolly.tarjeta_circulacion = e.target.files[0];
      //this.uploadFile(this.operador.documentacion.ine)
      console.log(e.target.files);
    },
   
    uploadFileTC(file, idtracto) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idtracto", idtracto);
      formData.append("tarjeta_circulacion", file);
      this.axios
        .post(
          url + "apiDollys.php/uploadTC",
          formData,

          {
            headers: {
              "Content-Type": file.type,
            },
          }
        )
        .then((respuesta) => {
          console.log(respuesta);
          // this.file= null;
          this.verdollyes();
        })
        .catch((respuesta) => {
          console.log(respuesta);
        });
    },

   
    uploadFileSeguro(file, idtracto) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idtracto", idtracto);
      formData.append("seguro", file);
      this.axios
        .post(
          url + "apiDollys.php/uploadSeguro",
          formData,

          {
            headers: {
              "Content-Type": file.type,
            },
          }
        )
        .then((respuesta) => {
          console.log(respuesta);
          // this.file= null;
          this.verdollyes();
        })
        .catch((respuesta) => {
          console.log(respuesta);
        });
    },
    uploadFileFactura(file, idtracto) {
      console.log("upload");
      var formData = new FormData();
      formData.append("idtracto", idtracto);
      formData.append("factura", file);
      this.axios
        .post(
          url + "apiDollys.php/uploadFactura",
          formData,

          {
            headers: {
              "Content-Type": file.type,
            },
          }
        )
        .then((respuesta) => {
          console.log(respuesta);
          // this.file= null;
          this.verdollyes();
        })
        .catch((respuesta) => {
          console.log(respuesta);
        });
    }, 
        savedolly() {
            console.log(this.validarcampos())
      if (this.validarcampos()) {
        if (this.dolly != null) {
          console.log(this.dolly);
          this.axios
            .post(url + "apiDollys.php/saveDollys", this.dolly)
            .then((respuesta) => {
              console.log(respuesta);
              if (respuesta.data != 0) {
                this.edit = false;
              

                if (this.dolly.tarjeta_circulacion != null) {
                  console.log("xxx");
                  console.log(
                    this.uploadFileTC(
                      this.dolly.tarjeta_circulacion,
                      respuesta.data
                    )
                  );
                }
            

                if (this.dolly.seguro != null) {
                  this.uploadFileSeguro(this.dolly.seguro, respuesta.data);
                }

                if (this.dolly.factura != null) {
                  this.uploadFileFactura(this.dolly.factura, respuesta.data);
                }
                this.limpiarcampos();
				this.$router.push("/catalogos/dollys");

              }
			  
            })
            .catch(function () {
              console.log("FAILURE!!");
            });
        }
      } else {
        this.$swal(
          "ABC Dollys",
          "Debe ingresar todos los datos del dolly (Sólo documentación es opcional)",
          "warning"
        );
      }
    },
    selectlasttipo(){
      console.log('xxx');
  var size=this.lista_tipos.length;
          this.dolly.idtipo_dolly = this.lista_tipos[size-1].idtipo_dolly;
    },
    limpiarcampos() {
      this.dolly = {};
      this.nombre_dolly = "";

      //Limpiar campo de tarjeta de circulación
      const tcinput = this.$refs.tcinput;
      tcinput.type = "text";
      tcinput.type = "file";


      //Limpiar campo de póliza de seguro
      const polizainput = this.$refs.polizainput;
      polizainput.type = "text";
      polizainput.type = "file";

      //Limpiar campo de factura

      const facturainput = this.$refs.facturainput;
      facturainput.type = "text";
      facturainput.type = "file";
    },
    
   
    validarcampos() {
        console.log(this.dolly);
      if (
        this.dolly.no_economico == "" 
        //||
        // this.dolly.telefono == ""
      ) {
        return false;
      } else {
        return true;
      }
    }
    }
      
    }
</script>
